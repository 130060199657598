import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import styled from "styled-components"
import SEO from "../components/seo"
import { Container } from "../components/styledComponents"
import { BigMobileFont } from "../components/typography"
import SocialIcon from "../components/socialIcon"
import LastCTASection from "../components/lastCTASection"
import BaseImg from "../components/baseImg"

const ArticleInfoBox = styled.div`
  box-shadow: 0px 3px 6px #00000029;
  padding: 36px 52px;
  background: #fff;
  font-size: 1.125rem;
  max-width: 580px;
`

const HeroSection = styled.div`
  min-height: calc(100vh - 160px);
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  @media (max-width: 900px) {
    min-height: auto;
    flex-direction: column;
  }
`

const HeroImg = styled(BaseImg)`
  position: relative;
  z-index: -1;
  width: 60%;
  margin: 0 -20% 0 0;
  @media (max-width: 900px) {
    width: 100%;
    margin: 0 0 -20% 0;
  }
  @media (max-width: 768px) {
    margin: 0;
  }
`

const ArticleContent = styled.div`
  line-height: 1.625;
  margin-bottom: 100px;
  h2 {
    font-size: 1.5rem;
    margin: 3.25rem 0 0.75rem;
  }
  h3 {
    font-size: 1.125rem;
    margin: 3.25rem 0 0.75rem;
  }
  img {
    width: 100%;
    margin: 40px 0;
  }
  .wp-block-columns {
    display: flex;
  }
  .wp-block-column + .wp-block-column {
    margin-left: 2rem;
  }
`

const SingleArticle = ({ pageContext }) => {
  const { wordpressPage } = useStaticQuery(graphql`
    query {
      wordpressPage(
        template: { eq: "page-templates/page-global-settings.php" }
      ) {
        cmb2 {
          metabox_global_settings_templates {
            articleShare
            articlePrevious
            articleNext
            articleLastCTASectionTitle
            articleLastCTASectionText
            articleLastCTASectionButtonUrl
            articleLastCTASectionButtonText
          }
        }
      }
    }
  `)
  const {
    articleShare,
    articlePrevious,
    articleNext,
    articleLastCTASectionTitle,
    articleLastCTASectionText,
    articleLastCTASectionButtonUrl,
    articleLastCTASectionButtonText,
  } = wordpressPage.cmb2.metabox_global_settings_templates
  const {
    title,
    content,
    author,
    featured_media,
    date,
    categories,
    socialIcons,
    next,
    previous,
    yoast_meta,
    path,
  } = pageContext
  const facebookButton = socialIcons.find(
    (file) => file.name === "facebookButton"
  )
  const linkedinButton = socialIcons.find(
    (file) => file.name === "linkedinButton"
  )
  return (
    <Layout>
      <SEO
        meta={yoast_meta}
        title={title}
        image={featured_media?.localFile?.publicURL}
        path={path}
      />
      <Container size="medium">
        <HeroSection>
          <HeroImg alt="" localFile={featured_media.localFile} />
          <ArticleInfoBox>
            {date} {categories.map((category) => "| " + category.name)}
            <h1 style={{ margin: "0.75rem 0" }}>
              <BigMobileFont>{title}</BigMobileFont>
            </h1>
            {author.name}
          </ArticleInfoBox>
        </HeroSection>
      </Container>
      <Container size="small">
        <ArticleContent
          className="article-content"
          dangerouslySetInnerHTML={{ __html: content }}
        ></ArticleContent>
      </Container>
      <Container size="medium">
        <div
          style={{
            fontWeight: "700",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "3rem",
          }}
        >
          {articleShare}:
          <div style={{ height: "34px" }}>
            <SocialIcon
              style={{ margin: "0 -10px 0 10px" }}
              src={facebookButton.publicURL}
              alt="Facebook logo"
              rel="noopener noreferrer"
              url={`https://www.facebook.com/sharer/sharer.php?u=${path}`}
            />
            <SocialIcon
              src={linkedinButton.publicURL}
              alt="LinkedIn logo"
              rel="noopener noreferrer"
              url={`https://www.linkedin.com/shareArticle?mini=true&url=${path}&title=${title}`}
            />
          </div>
        </div>
        <div
          style={{
            fontWeight: "700",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {previous ? (
            <Link to={previous.path}>{`< ${articlePrevious}`}</Link>
          ) : (
            <div />
          )}
          {next ? <Link to={next.path}>{`${articleNext} >`}</Link> : <div />}
        </div>
      </Container>
      <LastCTASection
        title={articleLastCTASectionTitle}
        text={articleLastCTASectionText}
        buttonText={articleLastCTASectionButtonText}
        buttonUrl={articleLastCTASectionButtonUrl}
      />
    </Layout>
  )
}

export default SingleArticle
